button, a, ion-button {
  font-weight: 700;
  font-size: 1em;
  margin: 8px 0;
  color: white;
  height: 45px;
  --border-width: .5px;

  &.rounded-full {
    border-radius: 9999px;
    --border-radius: 9999px;
  }
}
