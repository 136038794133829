input, select, textarea {
  border: 1px solid #ccc;
  border-radius: 9999px;
  background-color: #fff;
  padding: 6px 16px;
  height: 45px;
  margin: 8px 0;
  color: #1e2023;
}

form.ng-submitted {
  input, select {
    &.ng-invalid {
      border-color: var(--ion-color-secondary)
    }
  }
}

small {
  &.validation_error {
    margin-top: -5px;
    color: var(--ion-color-secondary);
  }
}
