.introjs-tooltip {
  max-width: none !important;
}

.introjs-tooltipbuttons {
  a {
    &.introjs-disabled {
      opacity: .5;
    }

    &.introjs-prevbutton {
      background: transparent;
      color: var(--ion-color-primary);
      border-color: var(--ion-color-primary);
      max-height: 24px;
      padding: 8px 32px;
      text-shadow: none;
      font-size: 16px;
      display: flex;
    }

    &.introjs-nextbutton {
      background: var(--ion-color-primary);
      color: var(--ion-color-primary-contrast);
      max-height: 24px;
      padding: 8px 32px;
      text-shadow: none;
      font-size: 16px;
      display: flex;

    }
  }
}
