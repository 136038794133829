/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
@tailwind base;
@tailwind components;
@tailwind utilities;
/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

/**
 * Ionic Dark Mode
 * -----------------------------------------------------
 * For more info, please see:
 * https://ionicframework.com/docs/theming/dark-mode
 */

/* @import "@ionic/angular/css/palettes/dark.always.css"; */
/* @import "@ionic/angular/css/palettes/dark.class.css"; */
// @import "@ionic/angular/css/palettes/dark.system.css";

* {
  font-family: 'FontRegular', sans-serif;
}

ion-toolbar {
  --min-height: 55px;
}

.logo {
  width: 100%;
  display: flex;
  justify-content: center;

  ion-img {
    width: 125px;
  }
}

.card {
  margin: 32px;
  color: white;
}

ion-toolbar {
  ion-icon {
    --background-color: black;
    --background-color: black;
  }
}
.fab {
  contain: layout;
}

.new_planing_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
  height: 100%;
}

ion-modal {
  --height: auto;
}
